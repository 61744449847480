import {KeycloakService} from 'keycloak-angular';
import {environment} from '../../../environments/environment';

export function initializeKeycloak(
  keycloak: KeycloakService
) {
  return () =>
    keycloak.init({
      config: {
        url: environment.KEYCLOAK_CONFIG.url,
        realm: environment.KEYCLOAK_CONFIG.realm,
        clientId: environment.KEYCLOAK_CONFIG.clientId,
      }, initOptions: {
        checkLoginIframe: false
      }
    });
}
