import {Injectable} from '@angular/core';
import {Message_Type, SweetAlert} from '../sweet.alert';
import {IMenuService} from '../../layouts/expose-out/service/menu.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ResponseService {

  public static ERROR = 'error';
  public static RESPONSE_MAP = 'responseMap';
  public static RESPONSE_MESSAGE = 'MESSAGE';
  public static RESPONSE_OBJECT_AFFECTED = 'objectAffected';
  public static RESPONSE_OBJECT_AFFECTED_ID = 'objectAffectedId';

  constructor(private menuService: IMenuService, private router: Router) {
  }

  public checkErrorResponse(err) {
    switch (err.status) {
      case 401: {
        SweetAlert.showMessageOnTopCorner(err.message, Message_Type.ERROR);
        this.menuService.signOut();
        break;
      }
      case 400: {
        SweetAlert.showMessageOnCenter(err[ResponseService.ERROR][ResponseService.RESPONSE_MAP][ResponseService.RESPONSE_MESSAGE], Message_Type.ERROR);
        break;
      }
      case 403: {
        SweetAlert.showMessageOnTopCorner('You\'re not authorized to perform this action!', Message_Type.ERROR);
        break;
      }
      default: {
        SweetAlert.showMessageOnTopCorner(err.message, Message_Type.ERROR);
        break;
      }
    }
  }

}
