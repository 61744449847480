import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BridgeProgressAPI, BridgeStageAPI} from '../backend.api.config';
import {Observable} from 'rxjs';
import {
  BridgeProgressExchange,
  BridgeProgressListFilter,
  BridgeProgressNotReportedViewExchange,
  BridgeProgressTransactionExchange,
  BridgeProgressViewExchange,
  CurrentBridgeProgressViewExchange
} from '../../model/bridge.progress.model';
import {BridgeStageModel} from '../../model/bridge.stage.model';

@Injectable({
  providedIn: 'root'
})
export class BridgeProgressService {

  constructor(private httpClient: HttpClient) {
  }

  public getBridgeProgressTransactions(filter: BridgeProgressListFilter): Observable<BridgeProgressViewExchange[]> {
    return this.httpClient.post<BridgeProgressViewExchange[]>(BridgeProgressAPI.LIST, filter);
  }

  public getBridgeProgressNotReportedTransactions(delayedDuration, bridgeNo, unitId,
                                                  issueStatus): Observable<BridgeProgressNotReportedViewExchange[]> {
    const httpParams = new HttpParams()
      .set('delayed_duration', delayedDuration)
      .set('bridge_no', bridgeNo)
      .set('unit_id', unitId)
      .set('issue_status', issueStatus);
    return this.httpClient.get<BridgeProgressNotReportedViewExchange[]>(BridgeProgressAPI.LIST_NOT_REPORTED, {params: httpParams});
  }

  public getBridgeProgressTransaction(id): Observable<BridgeProgressTransactionExchange> {
    return this.httpClient.get<BridgeProgressTransactionExchange>(BridgeProgressAPI.GET + '/' + id);
  }

  public getLatestBridgeProgressTransactionByBridgeId(id) {
    return this.httpClient.get(BridgeProgressAPI.GET_LATEST_BY_BRIDGE + '/' + id);
  }

  public listBridgeStage(): Observable<BridgeStageModel[]> {
    return this.httpClient.get<BridgeStageModel[]>(BridgeStageAPI.LIST);
  }

  public listCurrentBridgeProgress(): Observable<CurrentBridgeProgressViewExchange[]> {
    return this.httpClient.get<CurrentBridgeProgressViewExchange[]>(BridgeProgressAPI.LIST_CURRENT_PROGRESS);
  }

  public listAllBridgeProgressesByBridge(bridgeId: number): Observable<BridgeProgressExchange[]> {
    return this.httpClient.get<BridgeProgressExchange[]>(BridgeProgressAPI.LIST_ALL_PROGRESS + '/' + bridgeId);
  }

  public deleteLatestBridgeProgressByBridgeId(bridgeId: number) {
    return this.httpClient.delete(BridgeProgressAPI.DELETE_LATEST_BY_BRIDGE + '/' + bridgeId);
  }
}
