import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DistrictAPI} from '../backend.api.config';
import {DistrictModel} from '../../model/district.model';

@Injectable({
  providedIn: 'root'
})
export class DistrictService {

  constructor(private _http: HttpClient) {
  }

  public listDistrict(): Observable<DistrictModel[]> {
    return this._http.get<DistrictModel[]>(DistrictAPI.LIST);
  }

  public listAllDistrictsWithCount(): Observable<DistrictModel[]> {
    return this._http.get<DistrictModel[]>(DistrictAPI.LIST + '/count');
  }

  public saveDistrict(unit: DistrictModel) {
    return this._http.post(DistrictAPI.SAVE, unit);
  }

  public deleteDistrict(districtId: number) {
    return this._http.delete(DistrictAPI.DELETE + '/' + districtId);
  }
}
