import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {JWTParserService} from './security/jwt.parser.service';
import {ConfigService} from './config.service';
import {KeycloakService} from 'keycloak-angular';
import {environment} from '../../../environments/environment';

@Injectable()
export class AuthService {


  constructor(private _router: Router,
              private _http: HttpClient,
              private jwtParserService: JWTParserService,
              private configService: ConfigService,
              private keyCloakService: KeycloakService) {
  }

  public getAuthService() {
    return this.keyCloakService;
  }

  public async login(url: string) {
    await this.keyCloakService.login({
      redirectUri: url,
    });
  }

  public getToken(): Promise<string> {
    return this.keyCloakService.getToken();
  }

  public hasRole() {
    this.keyCloakService.addTokenToHeader()
  }

  public async isLoggedIn(): Promise<boolean> {
    return this.keyCloakService.isLoggedIn();
  }

  public logout() {
    this.keyCloakService.logout(environment.KEYCLOAK_CONFIG.logout_uri);
  }

}


