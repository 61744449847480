import {Menu} from '../model/menu';

export abstract class IMenuService {


  abstract getMenuDataForTheUserContext(): Menu[];

  abstract signOut();

  abstract afterSignOutRedirectURL(): string;
}
