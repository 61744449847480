import {UserModel} from './user.model';

export class IExchangeUpdateUser {
  userExchange: UserModel;
  userAttributesToUpdate: USER_ATTRIBUTE_TO_UPDATE[];
  currentPassword: string;
}

export enum USER_ATTRIBUTE_TO_UPDATE {
  FIRST_NAME = 'FIRST_NAME', LAST_NAME = 'LAST_NAME', EMAIL = 'EMAIL', PASSWORD = 'PASSWORD', REF_NO = 'REF_NO', PHONE = 'PHONE', USER_ATTRIBUTES = 'USER_ATTRIBUTES', ROLES = 'ROLES'
}
