import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {SpinnerService} from '../spinner.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private spinnerService: SpinnerService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.spinnerService.openSpinner();
    return next.handle(req).pipe(
      tap(e => {
        if (e instanceof HttpResponse) {
          this.spinnerService.hideSpinner();
        }
        return e;
      }),
      catchError((error: any) => {
        this.spinnerService.hideSpinner();
        return next.handle(req);
      })
    );
  }

}
