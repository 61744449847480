import {Injectable} from '@angular/core';
import {DatePipe} from '@angular/common';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {ReportAPI} from '../backend.api.config';
import {MonthlyReportBridgeModel} from 'app/model/bridge.monthly.report.model';
import {MonthlyReportSubmitModel} from 'app/model/bridge.monthly.report.submit.model';
import {Observable} from 'rxjs';
import {MonthlyReportCommentModel} from '../../model/bridge.monthly.report.comment.model';


@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private datePipe: DatePipe, private httpClient: HttpClient) {
  }

  public getBridgeProgressSummaryReport(): any {
    return this.httpClient.get(ReportAPI.REPORT_BRIDGE_PROGRESS, {responseType: 'blob'}).pipe(map(res => {
      return new Blob([res], {type: 'application/pdf'});
    }));
  }

  public getBridgeProgressNotReportedReport(bridgeNo, unitId, delayedDuration, issueStatus): any {
    const params = new HttpParams().set('bridgeNo', bridgeNo).set('unitId', unitId).set('delayedDuration', delayedDuration).set('issueStatus', issueStatus);
    return this.httpClient.get(ReportAPI.REPORT_BRIDGE_PROGRESS_NOT_REPORTED, {
      responseType: 'blob',
      params
    }).pipe(map(res => {
      return new Blob([res], {type: 'application/pdf'});
    }));
  }

  public getBridgeWarrantyReport(): any {
    return this.httpClient.get(ReportAPI.REPORT_BRIDGE_WARRANTY, {responseType: 'blob'}).pipe(map(res => {
      return new Blob([res], {type: 'application/pdf'});
    }));
  }

  public getProjectDeliverableReport(): any {
    return this.httpClient.get(ReportAPI.REPORT_PROJECT_DELIVERABLE, {responseType: 'blob'}).pipe(map(res => {
      return new Blob([res], {type: 'application/pdf'});
    }));
  }

  public getCurrentProgressReport(): any {
    return this.httpClient.get(ReportAPI.REPORT_CURRENT_PROGRESS, {responseType: 'blob'}).pipe(map(res => {
      return new Blob([res], {type: 'application/pdf'});
    }));
  }

  public getUnitProgressReport(bridgeNo): any {
    const params = new HttpParams().set('bridgeNo', bridgeNo);
    return this.httpClient.get(ReportAPI.REPORT_UNIT_PROGRESS, {responseType: 'blob', params}).pipe(map(res => {
      return new Blob([res], {type: 'application/pdf'});
    }));
  }

  public getBridgeIssueReport(): any {
    return this.httpClient.get(ReportAPI.REPORT_BRIDGE_ISSUES, {responseType: 'blob'}).pipe(map(res => {
      return new Blob([res], {type: 'application/pdf'});
    }));
  }

  public listMonthlyReportSelection() {
    return this.httpClient.get(ReportAPI.REPORT_MONTHLY_REPORT_SELECTION);
  }

  public listMonthlyReportBridgeData(monthlyReportId: number) {
    return this.httpClient.get(ReportAPI.REPORT_MONTHLY_REPORT_BRIDGE_LIST + '/' + monthlyReportId);
  }

  public generateMonthlyReport(bridgeList: MonthlyReportBridgeModel[], reportVersion) {

    const reportImageData: MonthlyReportSubmitModel[] = bridgeList.map(e => {
      const bridge = new MonthlyReportSubmitModel();
      bridge.bridgeId = e.id;

      // Set lhs image
      if (e.lhsProgressImageList.length === 0) {
        bridge.lhsProgressImage = '';
      } else {
        const selectedImage = e.lhsProgressImageList.find(i => i.selected);
        const selectedImagePath = selectedImage ? selectedImage.imagePath : e.lhsProgressImageList[0].imagePath;
        bridge.lhsProgressImage = selectedImagePath;
      }

      // Set rhs image
      if (e.rhsProgressImageList.length === 0) {
        bridge.rhsProgressImage = '';
      } else {
        const selectedImage = e.rhsProgressImageList.find(i => i.selected);
        const selectedImagePath = selectedImage ? selectedImage.imagePath : e.rhsProgressImageList[0].imagePath;
        bridge.rhsProgressImage = selectedImagePath;
      }

      // Set issue image
      if (e.bridgeIssueImageList.length === 0) {
        bridge.bridgeIssueImage = '';
      } else {
        const selectedImage = e.bridgeIssueImageList.find(i => i.selected);
        const selectedImagePath = selectedImage ? selectedImage.imagePath : e.bridgeIssueImageList[0].imagePath;
        bridge.bridgeIssueImage = selectedImagePath;
      }

      return bridge;
    });

    const data = {
      id: '',
      reportFinalBridgeSelectionExchangeList: reportImageData,
      reportVersion: reportVersion
    };

    return this.httpClient.post(ReportAPI.REPORT_MONTHLY_REPORT_GENERATE, data);
  }

  public listMonthlyReportHistory(): Observable<any[]> {
    return this.httpClient.get<any>(ReportAPI.REPORT_MONTHLY_REPORT_LIST);
  }

  public approveMonthlyReport(reportId: number) {
    return this.httpClient.put<any>(ReportAPI.REPORT_MONTHLY_REPORT_APPROVE + '/' + reportId, null);
  }

  public deleteMonthlyReport(reportId: number) {
    return this.httpClient.delete<any>(ReportAPI.REPORT_MONTHLY_REPORT_DELETE + '/' + reportId);
  }

  public getMonthlyReportDownloadURL(reportId: number) {
    return this.httpClient.get(ReportAPI.REPORT_MONTHLY_REPORT_DOWNLOAD + '/' + reportId);
  }

  public downloadMonthlyReport(url: string) {
    return this.httpClient.get(url,
      {responseType: 'blob', reportProgress: true, observe: 'events'}
    );
  }

  public saveMonthlyReportComment(monthlyReportCommentModel: MonthlyReportCommentModel) {
    return this.httpClient.post(ReportAPI.REPORT_MONTHLY_REPORT_COMMENT, monthlyReportCommentModel);
  }

  public listMonthlyReportComments(reportId: number): Observable<MonthlyReportCommentModel[]> {
    return this.httpClient.get<MonthlyReportCommentModel[]>(ReportAPI.REPORT_MONTHLY_REPORT_COMMENT + '/' + reportId);
  }

  public deleteMonthlyReportComment(commentId: number) {
    return this.httpClient.delete<any>(ReportAPI.REPORT_MONTHLY_REPORT_COMMENT + '/' + commentId);
  }

  public getBridgeSpanBreakdownReport(): any {
    return this.httpClient.get(ReportAPI.REPORT_BRIDE_SPAN_BREAKDOWN, {responseType: 'blob'}).pipe(map(res => {
      return new Blob([res], {type: 'application/pdf'});
    }));
  }

}
