import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BridgeSpanAPI} from '../backend.api.config';
import {SpanModel} from '../../model/span.model';
import {Observable} from 'rxjs';

@Injectable()
export class BridgeSpanService {

  constructor(private http: HttpClient) {
  }

  public saveSpan(span: SpanModel) {
    return this.http.post(BridgeSpanAPI.SAVE, span);
  }

  public listSpan() {
    return this.http.get(BridgeSpanAPI.LIST);
  }

  public listSpanTypes(): Observable<string[]> {
    return this.http.get<string[]>(BridgeSpanAPI.SPAN_LIST_TYPE);
  }

  public listSpanBySpanType(spanType: string): Observable<number[]> {
    return this.http.get<number[]>(BridgeSpanAPI.LIST + '/' + spanType);
  }

  public deleteSpan(spanId: number) {
    return this.http.delete(BridgeSpanAPI.DELETE + '/' + spanId);
  }
}
