<div class="modal-header">
  <h4 class="modal-title">
    <i class="fa fa-user"></i>
    Profile - {{updateUserExchange.userExchange.username}}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <!--  <div class="form-group row">-->
  <!--    <div class="col-12">-->
  <!--      <div class="text-center">-->
  <!--        <div class="avatar-status bg-blue-grey">-->
  <!--          <img src="assets/images/avatar.jpg" alt="" class="rounded-circle">-->
  <!--        </div>-->
  <!--        <div class="mt-1">Account Usage</div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
  <div class="form-group row">
    <div class="col-6">
      <div class="card">
        <div class="card-header">
          Personal Information
        </div>
        <div class="card-block">
          <div class="form-group row">
            <label for="firstName" class="col-4 col-form-label">First name</label>
            <div class="col-7">
              <input class="form-control" [(ngModel)]="updateUserExchange.userExchange.firstName" type="text"
                     id="firstName">
            </div>
          </div>
          <div class="form-group row">
            <label for="lastName" class="col-4 col-form-label">Last name</label>
            <div class="col-7">
              <input class="form-control" [(ngModel)]="updateUserExchange.userExchange.lastName" type="text"
                     id="lastName">
            </div>
          </div>
          <div class="form-group row">
            <label for="phone" class="col-4 col-form-label">Mobile no</label>
            <div class="col-7">
              <input class="form-control" [(ngModel)]="updateUserExchange.userExchange.phone" type="text" id="phone">
            </div>
          </div>
          <div class="form-group row">
            <div class="col-5">
              <button type="button" class="btn btn-danger btn-icon mr-1 mb-1" (click)="cancelPersonalInformation()">
                <i class="fa fa-remove "></i>
                <span>Cancel</span>
              </button>
            </div>
            <div class="col-6">
              <button type="button" class="btn btn-info btn-icon mr-1 mb-1"
                      (click)="savePersonalInformation()">
                <i class="fa fa-save"></i>
                <span>Save Changes</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">

      <div class="card">
        <div class="card-header">
          Change Password
        </div>
        <div class="card-block">
          <div class="form-group row">
            <label for="current-password" class="col-4 col-form-label">Current</label>
            <div class="col-7">
              <input class="form-control" type="password" id="current-password"
                     [(ngModel)]="updateUserExchange.currentPassword">
            </div>
          </div>
          <div class="form-group row">
            <label for="new-password" class="col-4 col-form-label">New</label>
            <div class="col-7">
              <input class="form-control" type="password" id="new-password"
                     [(ngModel)]="updateUserExchange.userExchange.password">
            </div>
          </div>
          <div class="form-group row">
            <label for="retype-password" class="col-4 col-form-label">Re-type new</label>
            <div class="col-7">
              <input class="form-control" type="password" id="retype-password" [(ngModel)]="retypedPassword">
            </div>
          </div>
          <div class="form-group row">
            <div class="col-5">
              <button type="button" class="btn btn-danger btn-icon mr-1 mb-1" (click)="cancelChangePassword()">
                <i class="fa fa-remove "></i>
                <span>Cancel</span>
              </button>
            </div>
            <div class="col-6">
              <button type="button" class="btn btn-info btn-icon mr-1 mb-1" (click)="changePassword()">
                <i class="fa fa-save"></i>
                <span>Save Changes</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>

